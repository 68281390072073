import React from 'react';
import { Link } from 'gatsby';
import SingleColPageLayout from '../templates/single-col-page-layout';
import ConvertkitInlineForm, {
  DailyNewsletterForm,
} from '../components/ConvertkitInlineForm';
import MetaTags from '../components/MetaTags';

const Page = ({ path }) => (
  <SingleColPageLayout>
    <MetaTags title="Serverless First Newsletter" path={path} />
    <h1>Want to learn more about building with serverless?</h1>

    <div className="-mx-4 my-8 bg-primary-100 border-primary-200 md:border-4 px-4 md:px-8 py-2 md:rounded-xl">
      <h2>Join my main newsletter</h2>
      <p>
        I write and share useful articles, guides and deep dives on building
        serverless solutions with hundreds of developers, architects and CTOs on
        my main email list. I send about 3–4 emails per month. Join here:
      </p>
      <ConvertkitInlineForm
        ctaText="Sign up for main newsletter"
        ctaButtonBgColor="#C2410C"
      />
    </div>

    <div className="-mx-4 my-8 mt-16 bg-gray-100 px-4 md:px-8 py-2 md:rounded-xl">
      <h2>Join daily email list</h2>
      <p>
        In addition to longer-form pieces, I also publish short emails on
        building software with serverless on a daily-ish basis. They’re casual,
        easy to digest, and sometimes thought-provoking. If you want to receive
        these (as well as my longer articles), join here:
      </p>
      <DailyNewsletterForm />
      <p className="text-center text-base">
        <Link to="/emails/">View Daily Emails Archive</Link>
      </p>
    </div>
  </SingleColPageLayout>
);

export default Page;
